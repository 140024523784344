import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
export type BookAnAppoinmentProps = {}
export function BookAnAppoinment() {
    return (
        <section style={{ padding: '50px 0' }}>
            <Container >
                <Row className="justify-content-md-center" style={{ textAlign: 'center' }}>
                    <Col md={8}>
                        <h3 className="header-font">Let’s have a chat about how we can help you.   </h3>
                        <Link className="btn btn-warning btn-lg" style={{ fontSize: 20, fontWeight: 700 }} to="/1-on-1-phone-call">Schedule an Appointment with Us</Link>
                    </Col>

                </Row>
            </Container>
        </section>
    )
}