export const seoData = {
    index: {
        title: 'Solutions Financial Consultants | Build Your Future',
        description: 'Solutions Consultants are your personal financial advisers. Above everything else we provide clarity. Free Consultation - the first step to securing your future'    
      },
      whoWeAre: {
        title: 'Who We Are | Solutions Consultants | Financial Advisers ',
        description: 'We empower our clients to take control of their finances, building a solid foundation for retirement planning, and investment, cash flow and risk advice.'    
      } ,
      personalSolutions: {
        title: 'Personal Solutions | Solutions Financial Consultants ',
        description: 'Your personal financial advisers. We help you manage finances, build your future and retirement, and live the life you want. Book your Free 1-on-1 Consultation.'    
      } ,
      retirenmentSolutions: {
        title: 'Retirement Solutions | Solutions Financial Consultants',
        description: 'Retirement planning made simple. We help you every step of the way, with advice for superannuation, assets, and taxation management and Centre-link issues.'    
      } ,
      meetTheTeam: {
        title: 'Meet the Team | Solutions Consultants | Financial Advisers',
        description: 'Our experienced financial experts who will help build and protect your wealth. Book your free 1-on-1 consultation.  Finance management and retirement planning.'    
      } ,
      contactUs: {
        title: 'Solutions Consultants | Financial Advisers | Contact',
        description: 'Get in touch with our team of financial experts, to discuss how we can help you get ahead. Pay off your mortgage, save for retirement, and enjoy your life.'    
      } 
}
