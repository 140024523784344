import React  from 'react'
import Layout from '../components/layout'
import { Container, Row, Col  } from 'reactstrap' 
import banner from '../images/retirenment-solution.jpg'
import { BookAnAppoinment } from '../components/book0an-apoinment' 
import retbg from '../images/ret-bg.jpg' 
import logo from '../images/logo-black.png'
import retsolution from '../images/retritment-solution.png'
import { seoData } from '../data/seo-data'
/*
const testis = [
    {
        id: 1,
        image: retbg2,
        name: "Kev & Kris Rahley",
        text: " I am now able to plan for future retirement with a lot more confidence, as I have confidence in my consultants ability to do the best for me. I have not only seen an improvement in my super return but I have found that I have a better understanding of my week to week spend and how I should manage my own savings."
    },
    {
        id: 2,
        image: retbg3,
        name: "Peter Jackson ",
        text: " The long term benefit was that we have been able to retire when we wanted to, this was due to implementing strategies that were recommended to us. Marc was able to tailor investments to create the income we needed to meet our needs. I wanted to retire early due to the heavy workload as a Boilermaker/Maintenance Engineer."
    }
]
*/
const RetriementSolutions = () => { 
 
    return (
        <Layout
            seo={{ title: seoData.retirenmentSolutions.title, description: seoData.retirenmentSolutions.description, keyword: '' }}
            banner={<FreeConsulTationBanner />}
            navStayle="SIMPLE"
            footerType="HOMEPAGE"
            setUid={() => {} }
        >
             
            <section style={{ marginBottom: 80, marginTop: 50 }}>
                <Container >

                    <Row className="justify-content-md-center" style={{ marginBottom: 50 }} >
                        <Col md={8} style={{ textAlign: "center" }}>
                            <h5>We help our clients discover what is fundamentally important to them,  and then we work in the background to make sure they focus on one thing:  happiness. </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <p  >Your transition to retirement is an important step in your life.  We have a number of strategies to help you  make this transition as smooth as possible.</p>
                            <p >In addition to taxation management strategies, we also provide advice and guidance on Centrelink-related issues including the asset test and the income test and how to best manage your affairs to maximise your entitlements.</p>
                        </Col>
                        <Col md={7}>
                        <img style={{ marginTop: '-20px', }} src={retsolution} alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{ marginBottom: 50, padding: "250px 0 0 0", background: `url(${retbg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div style={{ backgroundColor: 'rgba(1, 107, 146, 0.88)', padding: 20, color: '#fff', marginTop: 130 }}>
                                <p style={{ fontWeight: 500 }}><i className="fas fa-quote-left pr-2"></i> I am now able to plan for future retirement with a lot more confidence, as I have confidence in my consultant's ability to do the best for me. I have not only seen an improvement in my super return but I have found that I have a better understanding of my week to week spend and how I should manage my own savings. <i className="fas fa-quote-right pr-2" /></p>
                                <p style={{ color: '#d4ca5a', fontWeight: 800 }}>- Kev & Kris Rahley -</p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.88)', padding: 20, color: '' }}>
                                <p style={{ fontWeight: 500 }}><i className="fas fa-quote-left pr-2"></i> The long term benefit was that we have been able to retire when we wanted to, this was due to implementing strategies that were recommended to us. Marc was able to tailor investments to create the income we needed to meet our needs. I wanted to retire early due to the heavy workload as a Boilermaker/Maintenance Engineer. <i className="fas fa-quote-right pr-2" /></p>
                                <p style={{ color: '#15abe3', fontWeight: 800 }}>- Peter Jackson - </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
             
            <BookAnAppoinment />
        </Layout>
    )
}

const FreeConsulTationBanner = () => {
    return (
        <>
            <div style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundPosition: 'top center', minHeight: 600 }}>
                <Container>
                    <Row style={{ paddingBottom: 50, textAlign: "center" }}>
                        <Col md={4} >
                            <p></p>
                        </Col>
                        <Col md={6}>
                            <div style={{ marginTop: '50%', background: 'rgba(1, 107, 146, 0.75)', padding: '10px 0' }}>
                                <h3 className="header-font" style={{ color: '#fff', fontSize: '5em', marginBottom: 0, width: 'auto' }}>Retirement </h3>
                                <img style={{ marginTop: -37 }} src={logo} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{ backgroundColor: 'rgb(1, 107, 146)', minHeight: 50 }}>

            </div>
        </>
    )
}

export default RetriementSolutions

/*


 
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                <CarouselIndicators
                    items={testis}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                />
                {testis.map(testi => {

                    const sty1 = { backgroundColor: 'rgba(1, 107, 146, 0.88)', padding: 20, color: '#fff', marginTop: 130, marginBottom: 100 }
                    const sty2 = { backgroundColor: 'rgba(1, 107, 146, 0.88)', padding: 20, color: '#fff', marginTop: 230, marginBottom: 0 }
                    const kosong =  testi.id === 2 ? <span/> : <Col md={6}></Col>
                    return (
                        <CarouselItem
                            onExiting={onExiting}
                            onExited={onExited}
                            key={testi.id}
                        >
                            <section style={{ padding: "80px 0 50px 0", backgroundImage: `url(${testi.image})`, backgroundSize: 'cever', backgroundPosition: 'center' }}>
                                <Container>
                                    <Row>
                                        {kosong}
                                        <Col md={6}>
                                            <div style={ testi.id == 1 ? sty1 : sty2 }>
                    <p style={{ fontWeight: 500 }}><i className="fas fa-quote-left pr-2"></i> {testi.text} <i className="fas fa-quote-right pr-2"></i> </p>
                                                <p style={{ color: '#15abe3', fontWeight: 800 }}>- {testi.name} -</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </CarouselItem>
                    );
                })}
                <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                        e.preventDefault();
                        previous();
                    }}
                    role="button"
                >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                        e.preventDefault();
                        next();
                    }}
                    role="button"
                >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
            </Carousel>

            */